<template>
  <div>
    <h1 class="mb-30">Заделы</h1>

    <div class="between ai-c gap-s mb-30">
      <VForm @request="loadTableData">
        <VInput
          type="search"
          placeholder="Поиск по названию и артикулу"
          name="search"
          class="input--search"
        />
      </VForm>
      <ImportFile
        v-if="$store.getters['permission/isAdmin']"
        url="warehouse"
      />
    </div>

    <AppTable
      :columns="columns"
      :items="tableData"
      :is-tree-table="isTreeTable"
      :loading="loading"
      is-sticky
    />
  </div>
</template>

<script>
import AppTable from '@/components/ui/AppTable'
import VForm from '@/components/Form/VForm'
import VInput from '@/components/Form/Vinput/VInput'
import ImportFile from '@/components/files/ImportFile'
import { getAll } from '@/api/request'
import { error } from '@/utils/error'
import { mapPositionReserveTableData } from '@/utils/map/mapPositionReserveTableData'
import { createColOrderLinks } from '@/const/table/columns'

export default {
  name: 'Warehouse',
  components: {
    AppTable,
    VForm,
    VInput,
    ImportFile
  },
  data () {
    return {
      url: 'warehouse/positions',
      urlTree: 'warehouse/positions-tree',
      params: {
        sort: '-id'
      },
      columns: [
        {
          title: 'Наименование',
          name: 'name'
        },
        {
          title: 'Артикул',
          name: 'article'
        },
        {
          title: 'Резерв',
          name: 'amount_reserved'
        },
        {
          title: 'Где используется',
          name: 'orders',
          ...createColOrderLinks()
        },
        {
          title: 'Свободные заделы',
          name: 'free_reserves'
        },
        {
          title: 'Всего на складах в 1C',
          name: 'amount'
        }
      ],
      reserves: [],
      dataTableTree: [],
      tableData: [],
      isTreeTable: true,
      loading: false
    }
  },
  async mounted () {
    await this.loadTableData()
  },
  computed: {},
  methods: {
    async loadTableData (formData) {
      if (this.loading) return

      try {
        this.loading = true
        this.tableData = []

        let params = {}
        let url = this.urlTree
        this.isTreeTable = true

        if (formData?.search) {
          params = {
            ...this.params,
            all: formData.search
          }
          url = this.url
          this.isTreeTable = false
        }

        const response = await getAll(url, params)

        this.tableData = response.data.map(mapPositionReserveTableData)
      } catch (e) {
        await this.$store.dispatch('alert/setAlertError', error(e.response.data.message))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
